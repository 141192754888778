<template>
	<div class="span">
		<!--banner blog-->
		<section class="blog_banner marginTop_fixed">
			<div class="container">
				<div class="row">
					<div class="col-md-12">
						<h1>Our <span>Thoughts</span> & <span>Ideas</span></h1>
					</div>
				</div>
			</div>
		</section>
		<!--end-->

		<!--Blog List-->
		<section class="bloglist">
			<div class="container">
				<div class="row">
					<!--blog item start-->
					<div class="col-md-6" v-for="(post, index) in blog" :key="index">
						<div class="blogitem">
							<div class="blogimg">
								<router-link
									:to="{ path: '/' + $i18n.locale + '/blog/' + post.link }"
								>
									<img
										:src="require(`@/assets/images/${post.featured_image}`)"
										:alt="post.title"
									/>
								</router-link>
							</div>
							<h3>
								<router-link
									:to="{ path: '/' + $i18n.locale + '/blog/' + post.link }"
									>{{ post.title }}
								</router-link>
							</h3>
							<div class="authorsc">
								<div class="author_img">
									<img
										:src="require(`@/assets/images/${post.author_image}`)"
										:alt="post.author"
									/>
								</div>
								<div class="author_info">
									<h5>{{ post.author }}</h5>
									<h6>{{ post.date }}</h6>
								</div>
							</div>
						</div>
					</div>
					<!--end-->
				</div>
			</div>
		</section>
		<!--end-->
	</div>
</template>

<script>
	import eng from "../locales/blog_en.json";
	import ita from "../locales/blog_it.json";
	import MainPage from "@/layouts/MainPage.vue";
	export default {
		props: {
			value: String,
		},
		//this for layout call
		created() {
			this.$parent.$emit("update:layout", MainPage);
		},
		//this for layout render
		render() {
			return this.$slots.default[0];
		},

		data() {
			return {
				eng: eng,
				ita: ita,
			};
		},
		computed: {
			blog() {
				return this.$i18n.locale === "en" ? this.eng : this.ita;
			},
		},
		//Meta info title + description + others meta
		metaInfo() {
			return {
				title: "SEO Suite",
				htmlAttrs: {
					lang: this.$i18n.locale,
				},
				meta: [
					{ name: "description", content: "SEO Suite" },
					{ property: "og:title", content: "SEO Suite" },
					{ property: "og:site_name", content: "SEO Suite" },
					{ property: "og:type", content: "website" },
					{ name: "robots", content: "index,follow" },
				],
			};
		},
		//end meta info
	};
</script>

<style lang="scss" scoped></style>
